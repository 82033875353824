<template>
  <b-row class="w-100">
    <b-col
      class="navbar"
      md="9"
      lg="3"
    >
      <b-link
        :to="$route.path === '/user-block' && isFromTikTok ? {path:'/user-block'} : {path:'/'}"
      >
        <div
          class="brand-logo navbar-brand mx-lg-1 my-lg-2"
        >
          <b-img
            alt="logo"
            name="logo"
            :class="appName ? 'logo-img' : 'logo-img-full'"
            :src="appLogoImage"
          />
          <h5 class="align-self-center brand-text name-logo mb-0 font-weight-bolder">
            {{ appName }}
          </h5>
        </div>
      </b-link>
    </b-col>
    <b-col
      lg="9"
      class="locale-controller"
    >
      <div class="locale-select">
        <locale v-if="isSMBAgencyPlatform" />
      </div>
    </b-col>
  </b-row>
</template>

<script>

import { $themeConfig } from '@themeConfig'
import {
  BImg, BRow, BCol, BLink,
} from 'bootstrap-vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import envMixin from '@/mixins/envMixin'

export default {
  components: {
    Locale,
    BImg,
    BRow,
    BCol,
    BLink,
  },

  mixins: [envMixin],

  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss" scoped>
  .navbar-brand {
    top: 1rem !important;
    display:flex;
    .name-logo {
      margin-left: 0.5rem;
      width: 60px;
      line-height: 1.2rem;
      white-space: normal;
      color: #3D405B;
    }
  }

  .logo-img {
    max-width: 60px;
  }

  .logo-img-full {
    width: 135px;
  }

  @media (max-width: 767px) {
    .navbar {
      left: -7px;
    }
  }

  .locale-controller{
    z-index: 999;
    list-style: none;
    right: 0;
    position: relative;

    .locale-select{
      position: absolute;
      top: 2.3rem;
      right: 0;
      z-index: 1;
    }
  }
</style>
